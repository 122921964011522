


// Extensions / Summernote
// --------------------------------------------------

($ => {
  'use strict';

  if (!$.summernote) {
    throw new Error('summernote.js required.');
  }

  // Default summernote icons
  const summernoteIcons = $.summernote.options.icons;

  $.summernote.options = $.extend($.summernote.options, {
    defaultIcons: summernoteIcons,

    icons: {
      align:         'fa fa-align-left',
      alignCenter:   'fa fa-align-center',
      alignJustify:  'fa fa-align-justify',
      alignLeft:     'fa fa-align-left',
      alignRight:    'fa fa-align-right',
      indent:        'fa fa-indent',
      outdent:       'fa fa-outdent',
      arrowsAlt:     'fa fa-arrows-alt',
      bold:          'fa fa-bold',
      caret:         'fa fa-caret-down',
      circle:        'fa fa-circle-o',
      close:         'fa fa-close',
      code:          'fa fa-code',
      eraser:        'fa fa-eraser',
      font:          'fa fa-font',
      frame:         'fa fa-',
      italic:        'fa fa-italic',
      link:          'fa fa-link',
      unlink:        'fa fa-unlink',
      magic:         'fa fa-magic',
      menuCheck:     'fa fa-check',
      minus:         'fa fa-minus',
      orderedlist:   'fa fa-list-ol',
      pencil:        'fa fa-pencil',
      picture:       'fa fa-picture-o',
      question:      'fa fa-question',
      redo:          'fa fa-repeat',
      square:        'fa fa-square-o',
      strikethrough: 'fa fa-strikethrough',
      subscript:     'fa fa-subscript',
      superscript:   'fa fa-superscript',
      table:         'fa fa-table',
      textHeight:    'fa fa-text-height',
      trash:         'fa fa-trash',
      underline:     'fa fa-underline',
      undo:          'fa fa-undo',
      unorderedlist: 'fa fa-list-ul',
      video:         'fa fa-video-camera',
    },
  });
})(jQuery);
