


// Extensions / Tooltip
// --------------------------------------------------

($ => {
  'use strict';

  if (!$.fn.tooltip) {
    throw new Error('tooltip.js required.');
  }

  const STATE_PARAM = 'data-state';

  const bsTooltipSetContent = $.fn.tooltip.Constructor.prototype.setContent;

  $.fn.tooltip.Constructor.prototype.setContent = function() {
    const state = this.$element.attr(STATE_PARAM);

    if (state) {
      $(this.tip()).addClass(`tooltip-${state.replace(/[^a-z0-9_-]/ig, '')}`);
    }

    bsTooltipSetContent.call(this);
  };
})(jQuery);
