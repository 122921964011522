


// Extensions / Popover
// --------------------------------------------------

($ => {
  'use strict';

  if (!$.fn.popover) {
    throw new Error('popover.js required.');
  }

  const STATE_PARAM = 'data-state';
  const STYLE_PARAM = 'data-style';

  var bsPopoverSetContent = $.fn.popover.Constructor.prototype.setContent;

  $.fn.popover.Constructor.prototype.setContent = function() {
    var $element = this.$element;
    var $tip     = $(this.tip());
    var state    = $element.attr(STATE_PARAM);
    var style    = ($element.attr(STYLE_PARAM) || '').toLowerCase().split(' ');

    if (state) {
      $tip.addClass(`popover-${state.replace(/[^a-z0-9_-]/ig, '')}`);
    }

    if (style.indexOf('dark') !== -1) {
      $tip.addClass(`popover-dark`);
    }

    if (style.indexOf('colorful') !== -1) {
      $tip.addClass(`popover-colorful`);
    }

    bsPopoverSetContent.call(this);
  };
})(jQuery);
